import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import { AppLayout } from 'svs-utils/react';

import { Home } from './components/index.js';

function AppRouter() {

    var componentProps = {};

    return (
        <Routes>
            <Route path='/' element={<AppLayout colorMode='darkMode' />}>
                <Route path=':homePath' element={<Home {...componentProps} />}>
                    <Route path=':homePath2' element={<Home {...componentProps} />}></Route>
                </Route>
                <Route path='404' element={<div>404</div>} />
                <Route index element={<Navigate to='/slideshow' />} />
            </Route>
        </Routes>
    );
}

export default AppRouter;
