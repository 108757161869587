/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
// import dateFormat from 'dateformat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { parseDate } from 'svs-utils/web';
import { Input, useAjaxGlobalCache, useAppLayout } from 'svs-utils/react';

import { useStateSlice } from 'utils/reactUtils.js';
import { randomizePhotosList, photoUrl } from 'utils/photoUtils.js';
import { faSolid } from 'utils/faIcons.js';
import Photo from './photo.js';

import './photoGrid.scss';

var supportsPassive = false;
try {
    // eslint-disable-next-line getter-return
    window.addEventListener('test', null, Object.defineProperty({}, 'passive', { get: function () { supportsPassive = true; } }));
} catch(e) {}

var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
var wheelOpt = supportsPassive ? { passive: false } : false;

function PhotoGrid(props) {
    var [photosSlice, setPhotosSlice] = useStateSlice('photos');
    var { photosLoaded, photosList } = photosSlice;

    var [grouping, setGrouping] = useState('rating');
    var [sorting, setSorting] = useState('date');
    var [currentPhoto, setCurrentPhoto] = useState(null);

    var { layoutSize } = useAppLayout();

    useEffect(() => {
        var onWindowKeyUp = (event) => {
            switch (event.key) {
                case 'Escape': setCurrentPhoto(null); break;
                case 'ArrowLeft': nextPhoto(-1); break;
                case 'ArrowRight': nextPhoto(1); break;
                default: break;
            }
        };

        window.addEventListener('keyup', onWindowKeyUp);

        return () => window.removeEventListener('keyup', onWindowKeyUp);
    }, [currentPhoto?.id]);

    // disable the page from scrolling when a photo is open full screen
    useEffect(() => {
        var disablePageScrolling = (event) => (currentPhoto && event.preventDefault());

        window.addEventListener(wheelEvent, disablePageScrolling, wheelOpt);

        return () => window.removeEventListener(wheelEvent, disablePageScrolling, wheelOpt);
    }, [currentPhoto?.id]);

    useAjaxGlobalCache({ endPoint: '/getSlideShowImagesInfo' }, (results) => {
        photosList = [];
        if (results.result) {
            photosList = results.photos.map((photo) => ({ ...photo, takenOn: parseDate(photo.takenOn), data: {} }));
        }

        photosList.sort((a, b) => b.takenOn - a.takenOn); // default sort by date

        setPhotosSlice({ photosLoaded: true, photosList });
    });

    var nextPhoto = (direction = 1) => {
        var { photosList: orderedPhotosList } = photosSlice;

        if (grouping === 'rating') {
            orderedPhotosList = [
                ...orderedPhotosList.filter((p) => p.rating === 5),
                ...orderedPhotosList.filter((p) => p.rating === 4),
                ...orderedPhotosList.filter((p) => p.rating === 3),
                ...orderedPhotosList.filter((p) => p.rating === 2),
                ...orderedPhotosList.filter((p) => p.rating === 1),
                ...orderedPhotosList.filter((p) => p.rating === 0),
            ];
        }

        var index = orderedPhotosList.findIndex((p) => p.id === currentPhoto.id);
        if (index < 0) {
            return;
        }

        index = (index + direction + orderedPhotosList.length) % orderedPhotosList.length;
        var photo = orderedPhotosList[index];

        if (photo) {
            setCurrentPhoto(photo);
        }
    };

    var handleNewSorting = (value) => {
        var { photosList } = photosSlice;

        if (value === sorting) {
            return;
        }

        if (value === 'random') {
            photosList = randomizePhotosList(photosList);
        } else if (value === 'date') {
            photosList.sort((a, b) => b.takenOn - a.takenOn);
        }

        setSorting(value);
        setPhotosSlice({ photosList });
    };

    // default XL settings
    var rowHeight = 200;
    var numberOfColumns = 6;
    var gridGap = 10;
    if (layoutSize === 'L') {
        numberOfColumns = 5;
    } else if (layoutSize === 'M') {
        numberOfColumns = 4;
    } else if (layoutSize === 'S') {
        numberOfColumns = 3;
    } else if (layoutSize === 'XS') {
        numberOfColumns = 2;
    }

    var photoGroups = [];
    if (photosLoaded) {
        photoGroups.push({ photos: photosList });

        if (grouping === 'rating') {
            photoGroups = [
                { label: '5 stars', photos: photosList.filter((p) => p.rating === 5) },
                { label: '4 stars', photos: photosList.filter((p) => p.rating === 4) },
                { label: '3 stars', photos: photosList.filter((p) => p.rating === 3) },
                { label: '2 stars', photos: photosList.filter((p) => p.rating === 2) },
                { label: '1 star', photos: photosList.filter((p) => p.rating === 1) },
                { label: 'Unrated', photos: photosList.filter((p) => p.rating === 0) },
            ];
        }
    }

    photoGroups = photoGroups.filter((g) => !!g.photos.length);

    return (
        <div className='photoGridContainer' style={{ padding: `50px ${gridGap}px ${gridGap}px` }}>
            {currentPhoto && (
                <div className='viewPhotoContainer'>
                    <Photo photo={currentPhoto} key={currentPhoto.id} />
                    <FontAwesomeIcon className='viewPhotoCloseX' icon={faSolid.faXmark} onClick={() => setCurrentPhoto(null)} />
                    <div className='photoControls noSelect'>
                        <div onClick={() => nextPhoto(-1)}>
                            <FontAwesomeIcon icon={faSolid.faChevronLeft} />
                        </div>
                        <div onClick={() => setCurrentPhoto(null)}>
                            <FontAwesomeIcon icon={faSolid.faImages} />
                        </div>
                        <div onClick={() => nextPhoto(1)}>
                            <FontAwesomeIcon icon={faSolid.faChevronRight} />
                        </div>
                    </div>
                </div>
            )}
            <div className='filterOptionsContainer'>
                <Input
                    type='select'
                    label='Sort By'
                    className='gridSelectBox'
                    selectItems={[{ value: 'random', label: 'Randomly' }, { value: 'date', label: 'Date' }]}
                    value={sorting}
                    onChange={handleNewSorting}
                />
                <Input
                    type='select'
                    label='Group By'
                    className='gridSelectBox'
                    selectItems={[{ value: 'none', label: 'No Grouping' }, { value: 'rating', label: 'Rating' }]}
                    value={grouping}
                    onChange={setGrouping}
                />
            </div>
            {photosLoaded && (
                photoGroups.map((group, i) => (
                    <div key={i} className='photoGridGroup'>
                        {!!group.label && (
                            <div className='gridGroupTitle'>{group.label}</div>
                        )}
                        <div
                            className='photoGrid'
                            style={{
                                gridGap: gridGap,
                                gridTemplateColumns: `repeat(${numberOfColumns}, calc(100% / ${numberOfColumns} - (${gridGap}px * ${numberOfColumns - 1} / ${numberOfColumns})))`,
                            }}
                        >
                            {group.photos.map((photo) => (
                                <div key={photo.id} className={classNames('gridPhoto')} style={{ height: rowHeight }}>
                                    <img
                                        className='filmStripPlaceholder'
                                        src={photoUrl(photo.id, 'thumbnail')}
                                        alt={photo.fileName}
                                        onClick={() => setCurrentPhoto(photo)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export default PhotoGrid;
