/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import dateFormat from 'dateformat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { round } from 'svs-utils/web';
import { useAppLayout } from 'svs-utils/react';

import { useStateSlice } from 'utils/reactUtils.js';
import { getPhotoExifData, photoUrl, useRatePhoto } from 'utils/photoUtils.js';
import { faRegular, faSolid } from 'utils/faIcons.js';

import './photo.scss';

function Photo(props) {
    var { exifMobilePosition = 'top', photo, photo: { exif }, visible = true } = props;

    var [, setPhotosSlice] = useStateSlice('photos');

    var [source, setSource] = useState('');
    var [showFullPhoto, setShowFullPhoto] = useState(visible);
    var [windowRatio, setWindowRatio] = useState(window.innerWidth / window.innerHeight);

    var ratePhoto = useRatePhoto();

    var photoContainerRef = useRef();
    var smallPhotoRef = useRef();
    var photoRef = useRef();

    var { handleOnWheel, handleMouseMove } = usePhotoMoveZoom(photoContainerRef, photoRef, smallPhotoRef);

    var handleWindowResize = () => setWindowRatio(window.innerWidth / window.innerHeight);

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (photo?.id && visible && !photo.exif) {
            getPhotoExifData(photo).then((result) => {
                if (result) {
                    setPhotosSlice({});
                }
            });
        }
    }, [photo?.id, visible]);

    // this ensures the full photo doesn't get removed while the fade transition is still happening
    useEffect(() => {
        if (visible) {
            setShowFullPhoto(true);
        } else {
            var showFullPhotoTimeout = setTimeout(() => setShowFullPhoto(false), 3 * 1000);
        }

        return () => {
            if (showFullPhotoTimeout) {
                clearTimeout(showFullPhotoTimeout);
            }
        };
    }, [visible]);

    var handleSetSource = (newSource) => {
        var sourceOrder = ['thumbnail', 'full'];
        var newSourceIndex = sourceOrder.indexOf(newSource);
        var oldSourceIndex = sourceOrder.indexOf(source);
        if (newSourceIndex > oldSourceIndex) {
            setSource(newSource);
        }
    }

    var style = {};
    var backgroundStyle = {};
    if (windowRatio > photo.ratio) {
        style.height = '100%';
        backgroundStyle.width = 'calc(100% + 100px)';
    } else {
        style.width = '100%';
        backgroundStyle.height = 'calc(100% + 100px)';
    }

    var cameraNames = {
        'ILCE-7M4': 'Sony a7 IV',
        'ILCE-6300': 'Sony a6300',
        'ILCE-6700': 'Sony a6700',
        'Canon EOS REBEL T1i': 'Canon REBEL T1i',
        'Canon EOS REBEL T6i': 'Canon REBEL T6i',
    };

    return (
        <div className={classNames('photoContainer', { visible })} index={photo.index} photoid={photo.id} ref={photoContainerRef}>
            <img className='photoBackground' src={photoUrl(photo.id, 'thumbnail')} alt='' style={backgroundStyle} />
            <img className='photo' src={photoUrl(photo.id, 'thumbnail')} alt='' style={style} ref={smallPhotoRef} onLoad={() => handleSetSource('thumbnail')} />
            {showFullPhoto && (
                <img
                    className='photo'
                    src={photoUrl(photo.id, 'full')}
                    alt=''
                    ref={photoRef}
                    style={{ ...style, /*left: `calc(50% + ${left}px)`, top: `calc(50% + ${top}px)`, transform: `translate(-50%, -50%) scale(${zoom}%)`*/ }}
                    onWheel={handleOnWheel}
                    onMouseMove={handleMouseMove}
                    onLoad={() => handleSetSource('full')}
                />
            )}
            {!!exif && (
                <div className={classNames('photoInfo', exifMobilePosition)} title={photo.fileName}>
                    {cameraNames[exif.Model] || exif.Model} - {dateFormat(photo.takenOn, 'mmm dS, yyyy')}
                    <br />
                    {exif.ExposureTime < 1 ? `1/${1 / exif.ExposureTime}sec` : `${exif.ExposureTime}s`} f/{round(exif.FNumber, 1)} ISO {exif.ISO}<br />
                    {exif.FocalLength}mm {!!exif.LensModel && `(${exif.LensModel})`}<br/>
                    <span className='ratingStarsContainer'>
                        <FontAwesomeIcon
                            className='ratingStar'
                            icon={photo.rating >= 1 ? faSolid.faStar : faRegular.faStar}
                            onClick={() => ratePhoto(photo, 1)}
                            title='Rate Photo a 1'
                        />
                        <FontAwesomeIcon
                            className='ratingStar'
                            icon={photo.rating >= 2 ? faSolid.faStar : faRegular.faStar}
                            onClick={() => ratePhoto(photo, 2)}
                            title='Rate Photo a 2'
                        />
                        <FontAwesomeIcon
                            className='ratingStar'
                            icon={photo.rating >= 3 ? faSolid.faStar : faRegular.faStar}
                            onClick={() => ratePhoto(photo, 3)}
                            title='Rate Photo a 3'
                        />
                        <FontAwesomeIcon
                            className='ratingStar'
                            icon={photo.rating >= 4 ? faSolid.faStar : faRegular.faStar}
                            onClick={() => ratePhoto(photo, 4)}
                            title='Rate Photo a 4'
                        />
                        <FontAwesomeIcon
                            className='ratingStar'
                            icon={photo.rating >= 5 ? faSolid.faStar : faRegular.faStar}
                            onClick={() => ratePhoto(photo, 5)}
                            title='Rate Photo a 5'
                        />
                    </span>
                    <span style={{ float: 'right', opacity: '0.5' }}>{source}</span>
                </div>
            )}
        </div>
    );
}

function usePhotoMoveZoom(photoContainerRef, photoRef, smallPhotoRef) {
    var left = 0;
    var top = 0;
    var zoom = 100;
    var tpCache = [];

    var { isMobile } = useAppLayout();
    var maxZoom = isMobile ? 1200 : 800;

    useEffect(() => {
        if (isMobile) {
            var photoContainer = photoContainerRef.current;

            console.log('setting mobile touch handlers');
            photoContainer.addEventListener('touchstart', handleTouchStart, { passive: false });
            photoContainer.addEventListener('touchmove', handlePinchZoom, { passive: false });
            photoContainer.addEventListener('touchcancel', handleTouchEnd, { passive: false }); // Use same handler for touchcancel and touchend
            photoContainer.addEventListener('touchend', handleTouchEnd, { passive: false });

            return () => {
                console.log('unsetting mobile touch handlers');
                photoContainer.removeEventListener('touchstart', handleTouchStart);
                photoContainer.removeEventListener('touchmove', handlePinchZoom);
                photoContainer.removeEventListener('touchcancel', handleTouchEnd);
                photoContainer.removeEventListener('touchend', handleTouchEnd);
            };
        }
    }, []);

    var applyNewPhotoStyle = (newLeft, newTop, newZoom) => {
        newLeft = newLeft == null ? left : newLeft;
        newTop = newTop == null ? top : newTop;
        newZoom = newZoom == null ? zoom : newZoom;

        if (photoRef.current) {
            if (newLeft !== left) {
                photoRef.current.style.left = `calc(50% + ${newLeft}px)`;
            }
            if (newTop !== top) {
                photoRef.current.style.top = `calc(50% + ${newTop}px)`;
            }
            if (newZoom !== zoom) {
                photoRef.current.style.transform = `translate(-50%, -50%) scale(${newZoom}%)`;
            }
        }

        left = newLeft;
        top = newTop;
        zoom = newZoom;
    };

    var handleOnWheel = (event) => {
        var newZoom = zoom - event.deltaY;
        newZoom = Math.min(maxZoom, newZoom);
        newZoom = Math.max(100, newZoom);

        var mousePercentHeight = event.clientY / window.innerHeight;
        var originalImageHeight = smallPhotoRef.current.height;
        var newImageHeight = originalImageHeight * (newZoom / 100);
        var excessHeight = newImageHeight - originalImageHeight;
        var maxHeightChange = excessHeight / 2;
        var newTop = -((excessHeight * mousePercentHeight) - maxHeightChange);

        var mousePercentWidth = event.clientX / window.innerWidth;
        var originalImageWidth = smallPhotoRef.current.width;
        var newImageWidth = originalImageWidth * (newZoom / 100);
        var excessWidth = newImageWidth - originalImageWidth;
        var maxWidthChange = excessWidth / 2;
        var newLeft = -((excessWidth * mousePercentWidth) - maxWidthChange);

        applyNewPhotoStyle(newLeft, newTop, newZoom);
    };

    var handleMouseMove = (event) => {
        if (zoom === 100) {
            return;
        }

        var mousePercentHeight = event.clientY / window.innerHeight;
        var originalImageHeight = smallPhotoRef.current.height;
        var newImageHeight = originalImageHeight * (zoom / 100);
        var excessHeight = newImageHeight - originalImageHeight;
        var maxHeightChange = excessHeight / 2;
        var newTop = -((excessHeight * mousePercentHeight) - maxHeightChange);

        var mousePercentWidth = event.clientX / window.innerWidth;
        var originalImageWidth = smallPhotoRef.current.width;
        var newImageWidth = originalImageWidth * (zoom / 100);
        var excessWidth = newImageWidth - originalImageWidth;
        var maxWidthChange = excessWidth / 2;
        var newLeft = -((excessWidth * mousePercentWidth) - maxWidthChange);

        applyNewPhotoStyle(newLeft, newTop);
    }

    var initialTouchLeft = 0;
    var initialTouchTop = 0;
    var initialPinchScale = 100;
    var handleTouchStart = (event) => {
        console.log('touch started');
        event.preventDefault();
        // Cache the touch points for later processing of 2-touch pinch/zoom
        if (event.targetTouches.length === 1) {
            tpCache.push(event.targetTouches[0]);
            initialTouchLeft = left;
            initialTouchTop = top;
        } else if (event.targetTouches.length === 2) {
            for (var targetTouch of event.targetTouches) {
                tpCache.push(targetTouch);
            }
            initialTouchLeft = left;
            initialTouchTop = top;
            initialPinchScale = zoom;
        }
    };

    var handlePinchZoom = (event) => {
        event.preventDefault();

        var getNewTopLeft = (distanceX, distanceY) => {
            var newLeft = 0;
            var newTop = 0;

            var originalImageHeight = smallPhotoRef.current.height;
            var newImageHeight = originalImageHeight * (zoom / 100);
            if (newImageHeight > window.innerHeight) {
                var halfWindowHeight = window.innerHeight / 2;
                var halfHeight = newImageHeight / 2;
                newTop = initialTouchTop + distanceY;
                newTop = Math.max(newTop, -halfHeight + halfWindowHeight);
                newTop = Math.min(newTop, halfHeight - halfWindowHeight);
            }

            var originalImageWidth = smallPhotoRef.current.width;
            var newImageWidth = originalImageWidth * (zoom / 100);
            if (newImageWidth > window.innerWidth) {
                var halfWindowWidth = window.innerWidth / 2;
                var halfWidth = newImageWidth / 2;
                newLeft = initialTouchLeft + distanceX;
                newLeft = Math.max(newLeft, -halfWidth + halfWindowWidth);
                newLeft = Math.min(newLeft, halfWidth - halfWindowWidth);
            }

            return { newLeft, newTop };
        };

        var distanceX = 0;
        var distanceY = 0;
        var newLeft = 0;
        var newTop = 0;

        if (event.targetTouches.length === 1 && event.changedTouches.length === 1) {
            var touch = tpCache.find((tp) => tp.identifier === event.targetTouches[0].identifier);

            if (touch) {
                distanceX = event.targetTouches[0].clientX - touch.clientX;
                distanceY = event.targetTouches[0].clientY - touch.clientY;
                ({ newLeft, newTop } = getNewTopLeft(distanceX, distanceY));

                applyNewPhotoStyle(newLeft, newTop);
            }
        } else if (event.targetTouches.length === 2 && event.changedTouches.length === 2) {
            // Check if the two target touches are the same ones that started
            // the 2-touch
            var touch1 = tpCache.find((tp) => tp.identifier === event.targetTouches[0].identifier);
            var touch2 = tpCache.find((tp) => tp.identifier === event.targetTouches[1].identifier);

            if (touch1 && touch2) {
                // Calculate the difference between the start and move coordinates
                var originalA = Math.abs(touch1.clientX - touch2.clientX);
                var originalB = Math.abs(touch1.clientY - touch2.clientY);
                var originalC = Math.sqrt(Math.pow(originalA, 2) + Math.pow(originalB, 2));

                var newA = Math.abs(event.targetTouches[0].clientX - event.targetTouches[1].clientX);
                var newB = Math.abs(event.targetTouches[0].clientY - event.targetTouches[1].clientY);
                var newC = Math.sqrt(Math.pow(newA, 2) + Math.pow(newB, 2));

                var scale = newC / originalC;

                var newZoom = initialPinchScale * scale;
                newZoom = Math.min(maxZoom, newZoom);
                newZoom = Math.max(100, newZoom);

                // also calculate x/y movement as you zoom
                var originalMidX = (touch1.clientX + touch2.clientX) / 2;
                var originalMidY = (touch1.clientY + touch2.clientY) / 2;
                var newMidX = (event.targetTouches[0].clientX + event.targetTouches[1].clientX) / 2;
                var newMidY = (event.targetTouches[0].clientY + event.targetTouches[1].clientY) / 2;
                distanceX = newMidX - originalMidX;
                distanceY = newMidY - originalMidY;
                ({ newLeft, newTop } = getNewTopLeft(distanceX, distanceY));

                applyNewPhotoStyle(newLeft, newTop, newZoom);
            } else {
                // empty tpCache
                tpCache = [];
            }
        }
    };

    var handleTouchEnd = (event) => {
        event.preventDefault();
        tpCache = [];
        initialPinchScale = 100;
        initialTouchLeft = 0;
        initialTouchTop = 0;
    }

    return { handleOnWheel, handleMouseMove, handleTouchStart, handlePinchZoom, handleTouchEnd };
}

// {
//     "ImageWidth": 5078,
//     "ImageHeight": 3385,
//     "BitsPerSample": 16,
//     "ImageDescription": "                               ",
//     "Make": "SONY",
//     "Model": "ILCE-7M4",
//     "Orientation": 1,
//     "SamplesPerPixel": 3,
//     "XResolution": 72,
//     "YResolution": 72,
//     "ResolutionUnit": 2,
//     "ModifyDate": "2023:05:23 11:14:33",
//     "Artist": "SvSantos",
//     "Rating": 0,
//     "ExposureTime": 0.001,
//     "FNumber": 6.699999809265137,
//     "ExposureProgram": 1,
//     "ISO": 5000,
//     "DateTimeOriginal": "2023:05:23 11:14:33",
//     "ExposureCompensation": 0,
//     "MeteringMode": 5,
//     "Flash": 16,
//     "FocalLength": 500,
//     "ColorSpace": 1,
//     "WhiteBalance": 0,
//     "SerialNumber": "06217636",
//     "LensModel": "E 150-500mm F5-6.7 A057"
// }

export default Photo;
