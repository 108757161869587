/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
// import dateFormat from 'dateformat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { parseDate } from 'svs-utils/web';
import { useAjaxGlobalCache, useInterval, useWindowListener } from 'svs-utils/react';

import { useStateSlice } from 'utils/reactUtils.js';
import { randomizePhotosList, photoUrl } from 'utils/photoUtils.js';
import { faSolid } from 'utils/faIcons.js';
import Photo from './photo.js';

import './photoSlideshow.scss';

var filmStripHeight = 150;
var slideshowDelay = 15 * 1000;

function PhotoSlideshow(props) {
    var [photosSlice, setPhotosSlice] = useStateSlice('photos');
    var { photosLoaded, photosList } = photosSlice;

    var [slideshowPhotos, setSlideshowPhotos] = useState(() => randomizePhotosList(photosList, [4, 5]));
    var [currentIndex, setCurrentIndex] = useState(0);
    var [slideshowPlaying, setSlideshowPlaying] = useState(false);
    var [filmStripView, setFilmStripView] = useState(false);

    useInterval(() => nextPhoto(), slideshowPlaying && slideshowPhotos?.length && slideshowDelay);
    useWindowListener('keyup', (event) => onBodyKeyUp(event));

    useEffect(() => {
        if (slideshowPhotos.length) {
            setSlideshowPlaying(true);
        }
    }, [slideshowPhotos]);

    useAjaxGlobalCache({ endPoint: '/getSlideShowImagesInfo' }, (results) => {
        photosList = [];
        if (results.result) {
            photosList = results.photos.map((photo) => ({ ...photo, takenOn: parseDate(photo.takenOn), data: {} }));
        }

        setPhotosSlice({ photosLoaded: true, photosList });

        if (!slideshowPhotos.length) {
            setSlideshowPhotos(randomizePhotosList(photosList, [4, 5]));
        }
    });

    var onBodyKeyUp = (event) => {
        if (event.key === 'ArrowLeft') {
            nextPhoto(-1);
        } else if (event.key === 'ArrowRight') {
            nextPhoto(1);
        } else if (event.key === ' ') {
            setSlideshowPlaying(!slideshowPlaying);
        }
    };

    var nextPhoto = (direction = 1, fromClick = false) => {
        if (fromClick && slideshowPlaying) {
            setSlideshowPlaying(false);
        }

        setCurrentIndex((prevCurrentIndex) => {
            var newIndex = (prevCurrentIndex + direction + slideshowPhotos.length) % slideshowPhotos.length;
            return newIndex;
        });
    };

    var goToPhoto = (photo) => {
        if (slideshowPlaying) {
            setSlideshowPlaying(false);
        }

        var newIndex = slideshowPhotos.findIndex((p) => p.id === photo.id);
        setCurrentIndex(newIndex);
    };

    var currentId = slideshowPhotos[currentIndex]?.id ?? null;

    return (
        <div className='photoSlideshow'>
            <div className={classNames('slideshowContainer', { filmStripView })}>
                {photosLoaded && (
                    slideshowPhotos.map((photo) => (
                        <Photo
                            exifMobilePosition='top'
                            key={photo.id}
                            photo={photo}
                            visible={photo.id === currentId}
                        />
                    ))
                )}
                <div className='slideshowControls noSelect'>
                    <div onClick={() => setFilmStripView(!filmStripView)}>
                        {/* <FontAwesomeIcon icon={faSolid.faGrip} /> */}
                        <FontAwesomeIcon icon={faSolid.faImages} />
                    </div>
                    <div onClick={() => nextPhoto(-1, true)}>
                        <FontAwesomeIcon icon={faSolid.faChevronLeft} />
                    </div>
                    <div className='playPause' onClick={() => setSlideshowPlaying(!slideshowPlaying)}>
                        {slideshowPlaying ? <FontAwesomeIcon icon={faSolid.faPause} /> : <FontAwesomeIcon icon={faSolid.faPlay} />}
                    </div>
                    <div onClick={() => nextPhoto(1, true)}>
                        <FontAwesomeIcon icon={faSolid.faChevronRight} />
                    </div>
                </div>
            </div>
            <div className={classNames('filmStripContainer', { filmStripView })}>
                {slideshowPhotos.map((photo) => (
                    <div key={photo.id} className={classNames('filmStripPhoto', { selected: photo.id === currentId })}>
                        <img
                            className='filmStripPlaceholder'
                            src={photoUrl(photo.id, 'thumbnail')}
                            alt={photo.fileName}
                            onClick={() => goToPhoto(photo)}
                            style={{ width: ((filmStripHeight - 10) * photo.ratio) }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PhotoSlideshow;
