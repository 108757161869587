import React from 'react';

import { randomString, sleep } from 'svs-utils/web';
import { getGlobalStateUtils } from 'svs-utils/react';

var initialGlobalState = {
    photos: {
        photosLoaded: false,
        photosList: [],
    },
};
export var { GlobalStateProvider, useStateSlice } = getGlobalStateUtils(initialGlobalState);
// export var { GlobalStateComponent, withNavigate, withParams, withProvider } = getLocalReactUtils(React, ReactRouter, initialGlobalState);

var numberOfWebSockets = 2;
var connectingWebsockets = false;
var websockets = [];
async function startWs() {
    while (connectingWebsockets) {
        await sleep(50);
    }
    websockets = websockets.filter((ws) => ws.readyState === WebSocket.OPEN);
    if (websockets.length >= numberOfWebSockets) {
        return;
    }

    console.log('starting websocket');
    connectingWebsockets = true;

    // the /temp is in here because redbird doesn't recognize the /ws endpoint when the ?token= is after it
    var protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
    var host = window.location.host;
    if (host.endsWith(':2901')) {
        host = host.replace(':2901', ':2900');
    }

    var toMake = Array.from(Array(numberOfWebSockets - websockets.length));
    var proms = toMake.map(() => new Promise((resolve, reject) => {
        var ws = new WebSocket(`${protocol}://${host}/api/ws/photoData`);
        ws.onopen = () => {
            ws.wsCalls = {};
            resolve(ws);
        };
        ws.onmessage = (event) => handleNewMessage(event);
        ws.onerror = (...args) => console.log('error connecting ws', args);
        ws.onclose = (event) => {
            console.log('ws close event', event);
            ws = null;
        };
    }));

    websockets = [...websockets, ...(await Promise.all(proms))];
    connectingWebsockets = false;
}

function handleNewMessage(event) {
    var myWs = event.target;
    var results = JSON.parse(event.data);
    if (myWs.wsCalls[results.ajaxId]) {
        myWs.wsCalls[results.ajaxId].results = results;
        myWs.wsCalls[results.ajaxId].loading = false;
    }
}

export async function wsAjax(action, data) {
    await startWs();

    var toChooseFrom = [...websockets];
    toChooseFrom.sort((a, b) => Object.keys(a.wsCalls).length - Object.keys(b.wsCalls).length);

    var myWs = toChooseFrom[0];
    var ajaxId = randomString();
    myWs.wsCalls[ajaxId] = {
        loading: true,
        results: null,
    };

    myWs.send(JSON.stringify({ ajaxId, action, data }));

    while (myWs.wsCalls[ajaxId].loading) {
        await sleep(100);
    }

    var results = myWs.wsCalls[ajaxId].results;
    delete myWs.wsCalls[ajaxId];

    return results;
}
