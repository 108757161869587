import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import { LoginProvider } from 'svs-utils/react';

import { GlobalStateProvider } from './utils/reactUtils.js';
import AppRouter from './router.js';
import reportWebVitals from './reportWebVitals.js';

import 'svs-utils/css/mainStyles.scss';
import 'svs-utils/css/fonts.css';
// import './index.scss';

var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <GlobalStateProvider>
                <LoginProvider requireLogin={false}>
                    <AppRouter />
                </LoginProvider>
            </GlobalStateProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
