import { ajax } from 'svs-utils/web';
import { createAlert, createNotify, useLogin } from 'svs-utils/react';

import { useStateSlice } from 'utils/reactUtils.js';

var url = window.location.origin.replace(':2901', ':2900');
export function photoUrl(photoId, fileSize) {
    if (url.endsWith(':2900')) {
        return `${url}/photo/${fileSize}/${photoId}`;
    } else {
        return `/photo/${fileSize}/${photoId}`;
    }
}

export async function getPhotoExifData(photo) {
    if (!photo.exif) {
        var exifResults = await ajax({ endPoint: '/getPhotoExifData', data: { photoId: photo.id } });
        if (exifResults.result) {
            photo.exif = exifResults.exif;
        }

        return exifResults.result;
    }

    return false; // we didn't do anything
}

export function useRatePhoto() {
    var login = useLogin();
    var [, setPhotosSlice] = useStateSlice('photos');

    return (photo, rating) => ratePhoto(photo, rating, login, setPhotosSlice);
}

async function ratePhoto(photo, rating, login, setPhotosSlice) {
    if (!login.loggedIn) {
        login.showLogInWindow();
        return;
    }

    var results = await ajax({ endPoint: '/ratePhoto', data: { photoId: photo.id, rating } });
    if (results.result) {
        photo.rating = rating;
        setPhotosSlice({});
        createNotify(`Successfully set rating to ${rating}`);
    } else {
        createAlert(results.desc);
    }
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function randomizePhotosList(photosList, ratings = [0, 1, 2, 3, 4, 5]) {
    var stars5 = ratings.includes(5) ? shuffleArray(photosList.filter((p) => p.rating === 5)) : [];
    var stars4 = ratings.includes(4) ? shuffleArray(photosList.filter((p) => p.rating === 4)) : [];
    var stars3 = ratings.includes(3) ? shuffleArray(photosList.filter((p) => p.rating === 3)) : [];
    var stars2 = ratings.includes(2) ? shuffleArray(photosList.filter((p) => p.rating === 2)) : [];
    var stars1 = ratings.includes(1) ? shuffleArray(photosList.filter((p) => p.rating === 1)) : [];
    var stars0 = ratings.includes(0) ? shuffleArray(photosList.filter((p) => p.rating === 0)) : [];

    // photosList = [...stars0, ...shuffleArray([...stars5, ...stars4]), ...stars3, ...stars2, ...stars1];
    photosList = [...stars0, ...stars5, ...stars4, ...stars3, ...stars2, ...stars1];

    return photosList;
}
