import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
// import dateFormat from 'dateformat';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import * as faIcons from '@fortawesome/free-solid-svg-icons';

import { confirmProm, Login, useAppLayout, useLogin } from 'svs-utils/react';

import PhotoGrid from './photoGrid.js';
import PhotoSlideshow from './photoSlideshow.js';
import './home.scss';

function Home(props) {
    var menuItems = {
        slideshow: { title: 'Slideshow', shouldShow: () => true, content: () => <PhotoSlideshow /> },
        grid: { title: 'Grid', shouldShow: () => true, content: () => <PhotoGrid /> },
    };

    var appLayout = useAppLayout();
    var navigate = useNavigate();
    var urlParams = useParams();
    var login = useLogin();

    var [menuOpen, setMenuOpen] = useState(false && !appLayout.isMobile && !['XS', 'S'].includes(appLayout.layoutSize));

    useEffect(() => {
        var path = urlParams.homePath?.toLowerCase();

        if (!menuItems[path] || (!menuItems[path].shouldShow?.() && !menuItems[path].onlyDirect)) {
            navigate('/404');
            return;
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    var showLogin = async () => {
        if (!login.loggedIn) {
            login.showLogInWindow();
            return;
        } else {
            if (await confirmProm('Are you sure you want to log out?')) {
                login.logout();
            }
        }
    }

    var menuOverlay = true || appLayout.menuOverlay;

    var homePath = urlParams.homePath?.toLowerCase();

    return (
        <div className='home'>
            {login.logInWindowOpen && (
                <Login useDialog={true} login={login.login} />
            )}
            <div className={classNames('mainToolBar noSelect', { menuOpen })}>
                <div className='mainMenuButton' onClick={() => setMenuOpen(!menuOpen)}>
                    Menu
                </div>
                <div className='titleBar'>{'svsantos.com' || menuItems[homePath]?.title}</div>
            </div>
            <div className={classNames('mainMenu', { menuOpen, menuOverlay })}>
                {Object.keys(menuItems).filter((itemKey) => (menuItems[itemKey].shouldShow?.() && !menuItems[itemKey].onlyDirect)).map((itemKey) => (
                    <Link
                        className={classNames('menuItem', { selected: homePath === itemKey })}
                        key={itemKey}
                        to={`/${itemKey}`}
                        onClick={() => menuOverlay && setMenuOpen(false)}
                    >
                        {menuItems[itemKey].title}
                    </Link>
                ))}
                <div className='menuItem loginButton' onClick={showLogin}>
                    {login.loggedIn ? `Log out: ${login.user.username}` : 'Login'}
                </div>
            </div>
            <div
                className={classNames('homeContentContainer')}
                style={{ gridColumn: `span ${(menuOpen && !menuOverlay) ? 1 : 2}` }}
            >
                <div className='homeContent'>
                    {menuItems[homePath]?.content()}
                </div>
            </div>
        </div>
    );
}

export default Home;
